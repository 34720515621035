import React from "react";
import BlobOne from '../Blobs/BlobOne';
import BlobThree from '../Blobs/BlobThree';
import Head from '../Head';
import DarkModeToggle from "react-dark-mode-toggle";
import { useState, useEffect } from 'react';
import Slide from './Slide/Slide';
import importedSlides from './Slides'; 
import { useTheme, useSetTheme } from '../../contexts/ThemeContext';
import { Link } from 'gatsby';

const Hero = () => {
    const [slideNumber, setSlideNumber] = useState(0);
    const [slides, setSlides] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { isDarkTheme, paragraphTextColor, backgroundColor } = useTheme();
    const { toggleTheme } = useSetTheme();

     // figure out best way to display info slides (such as SWESlide) and toggle them with the controller component

     // set slides on initial load
     useEffect( () => {
        let lc = importedSlides;
        setSlides([...slides, lc]);
        setTitle(importedSlides[0].title)
        setDescription(importedSlides[0].description)
     }, [])

     useEffect( () => {
        setTitle(importedSlides[slideNumber].title);
        setDescription(importedSlides[slideNumber].description);
     }, [slideNumber]);

     const nextSlide = () => {
        console.log('slide number next slide', slideNumber)
        slideNumber === 2 ? setSlideNumber(0) : setSlideNumber( state => state + 1)
     }

     useEffect(() => {
         const timer = () => {
             setInterval(() => {
                nextSlide()
                console.log('fired')
                console.log(slideNumber)
             }, 2000)
         }
         return () => {
            timer()
            clearInterval(timer)
         };
     }, [])

    // something like this, but using state?

    return(
        <header className="hero-container" style={{backgroundColor: backgroundColor, position: 'relative', zIndex: '-4'}}>
            <div className="first-blobset">
                <BlobOne className="blobOne" color="#9A4DFF" />
                {/* <BlobOne className="blobTwo" color="#C8F9E7" /> */}
            </div>
            <div className="top-bar">
                <div className="ad" >
                    <Link 
                        to="https://www.buymeacoffee.com/trentungard" 
                        target="blank" 
                        style={{color: paragraphTextColor}}
                    >Buy me a tea? 🍵 </Link>
                </div>
                <div className="toggle-parent">
                    <DarkModeToggle
                        onChange={toggleTheme}
                        checked={isDarkTheme}
                        size={60}
                    />
                </div>
                <div className="github-logo-parent">
                    <Link 
                        to="https://www.github.com/trentungard" 
                        target="blank"
                    >
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24">
                            <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                        </svg>
                    </Link>
                </div>
            </div>
            <div className="info-container">
                <Slide 
                    title={title} 
                    description={description} 
                    slideNumber={slideNumber} 
                    setSlideNumber={setSlideNumber} 
                />
                <div className="graphic">
                    <Head className="head" />
                </div>
            </div>
            <div>
                <BlobThree className="blobThree" color="#9A4DFF" />
            </div>
        </header>
    )
}

export default Hero;