import React from 'react';
import Label from '../../Label/Label';
import Controls from '../../Controls/Controls';
import { useTheme, useSetTheme } from '../../../contexts/ThemeContext';
import Theme from '../../Theme';

const Slide = ({title, description, slideNumber, setSlideNumber}) => {
    const { isDarkTheme, mainTextColor, secondaryTextColor, paragraphTextColor, tertiaryTextColor } = useTheme();
    const { toggleTheme } = useSetTheme();

    return(
        <div className="text-info">
            <div className="name-container">
                <h1 className="name" style={{color: mainTextColor}}>Trent Ungard</h1>
            </div>
            <Label isTitle={true} title={title} />
            <div className={isDarkTheme ? "section-text section-text-dark" : "section-text"}>
                <p style={{color: tertiaryTextColor}} className="text">{description}</p>
            </div>
            <Controls amount={3} slideNumber={ slideNumber } setSlideNumber={setSlideNumber}/>
        </div>
    )
}

export default Slide;