import React from 'react';

const Control = ({number, fill, onClick}) => {

    return(
        <div className="control" id={number} >
            <svg id="Layer_1" viewBox="0 0" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
                <defs/>
                <rect fill={fill} onClick={(e) => onClick(e)} id={number} height="6" width="60" />
            </svg>
        </div>
    )
}

export default Control;