import React, {useState, useEffect} from 'react';
import './RecentActivity.scss';
import { getTweets } from '../../helpers/getTweets';
import ContentCard from './ContentCard';
import Label from '../Label/Label';
import { useTheme } from '../../contexts/ThemeContext';


const RecentActivity = () => {
    const [recentTweets, setRecentTweets] = useState([]);
    const [twitterImageUrl, setTwitterImageUrl] = useState('');
    
    const handleUpdateTweets = (res) => {
        const tweets = res.data[0]
        console.log('setting tweets to', tweets)
        setRecentTweets(tweets)
    };

    const updateTwitterImageUrl = (url) => setTwitterImageUrl(url);

    const handleServerResponse = (res) => {
        handleUpdateTweets(res);
        updateTwitterImageUrl(res.data[1]);
    }
    
    useEffect(() => {
        console.log('ghettign tweets')
        getTweets(handleServerResponse)
    }, []);

    const { paragraphTextColor, backgroundColor, backgroundColorTwo } = useTheme();

    return(
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319" style={{backgroundColor: backgroundColorTwo}}>
            <path fill="#B1FBDE" fillOpacity="1" d="M0,0L34.3,42.7C68.6,85,137,171,206,224C274.3,277,343,299,411,304C480,309,549,299,617,250.7C685.7,203,754,117,823,74.7C891.4,32,960,32,1029,74.7C1097.1,117,1166,203,1234,208C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z">
            </path>
        </svg>
        <section className="activity-section" style={{marginTop: -5}}>
            <div className="recent-activity">
                <Label title="Recent Activity" alternate={true} />
                <div className="cards-container">
                    <ContentCard 
                        className="card twitter" 
                        content={recentTweets} 
                        avatarUrl={twitterImageUrl} 
                        handle="@NotTrentU" 
                        platform="Twitter"
                    />
                    {/* <ContentCard className="card instagram" content={["Coming soon!", "I said coming soon...", "Just take my word. Really.", "It's Facebook's fault. Really."]}/> */}
                </div>
            </div>
        </section>
        </>
    )
}

export default RecentActivity;