import React, { useState } from 'react';
import { GrNext } from 'react-icons/gr';

export default function ContentCard({avatarUrl, handle, content, className, platform}) {
        const [slideNumber, setSlideNumber] = useState(0);
        const handleIncSlide = () => slideNumber == 2 ? setSlideNumber(0) : setSlideNumber( state => state + 1)
        const handleDecSlide = () => slideNumber == 0 ? setSlideNumber(2) : setSlideNumber( state => state - 1)
        return (
            <div className={className}>
                <img className="avatar" src={avatarUrl}/>
                <h4 className="platform">{platform}</h4>
                <h6 className="handle">{handle}</h6>
                <GrNext className='arrow back-arrow' onClick={handleDecSlide} />
                { content.map( (tweet, i) => {
                    return(
                        <p 
                            className="content text" 
                            key={i}
                            style={{display: slideNumber === i ? 'initial' : 'none' }}
                            >{tweet.text}</p>
                    )
                }) }
                <GrNext className='arrow next-arrow' onClick={handleIncSlide} />
            </div>
        )
}
