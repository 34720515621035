import React, { useState, useEffect } from 'react';
import Control from './Control';

const Controls = ({ amount, slideNumber, setSlideNumber }) => {
    const [ controls, setControls ] = useState([]);

    // generates slides on initial load
    useEffect( () => {
        let lc = controls;
        for( let x = 0; x < amount; x++){
            lc.push(<Control number={x} fill="#d6d6d6" onClick={updateSlide} key={x} className="cls-1"/>)
        }
        setControls([...lc]);
    }, [])

    // updates the current slide when it changes
    useEffect( () => {
        let lc = controls.map( (control, i) => {
            return  i !== parseInt(slideNumber) ? <Control number={i} fill="#d6d6d6" onClick={updateSlide} key={i} /> : <Control number={slideNumber} fill="#4D267F" onClick={updateSlide} key={slideNumber} />
        })
        setControls([...lc])
    }, [slideNumber])

    const changeSlide = ( num ) => {
        setSlideNumber(num);
    }

    const incrementSlide = ( slideNumber ) => {
        slideNumber === controls.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1);
    }

    const decrementSlide = ( slideNumber ) => {
        slideNumber === 0 ? setSlideNumber(controls.length - 1) : setSlideNumber(slideNumber - 1);
    }

    const updateSlide = ( e ) => {
        // take event, compare ID of target to the current slide, increment or decrement until that slide is reached
        const clickedNumber = e.target.id;
        changeSlide(clickedNumber);
    }

    return(
        <div className="controls-parent">
            { controls } 
        </div>
    )
}

export default Controls;
