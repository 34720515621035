import axios from 'axios';

export const getTweets = ( callback ) => { 
    axios.get('https://tungard-personal-site-backend.herokuapp.com/getTweets')
        .then(res => {
            console.log('response')
            callback(res)
        })
        .catch(e => console.error('error', e))
}


