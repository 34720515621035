import React from 'react';
import Label from '../Label/Label';
import './HomeAbout.scss';
import { useTheme } from '../../contexts/ThemeContext';
import { Link } from 'gatsby';
import { HomeArt } from '../../assets/HomeArt';


const HomeAbout = () => {
    const { paragraphTextColor, backgroundColor, backgroundColorTwo } = useTheme();

    return(
        <>
            <section style={{backgroundColor: backgroundColor, color: paragraphTextColor}}>
                <div className="home-about-content-container">
                    <div className="home-about top">
                        <Label title="About Me" />
                        <p className="descriptor">
                        If it weren't already obvious, I am a Software Engineer! I specialize in Web Development,
                        but even more specifically creating robust and responsive User Interfaces. Moreover, I'm a lifelong learner that is passionate about technology, regardless of the format. 
                        I have quite a few hobbies that you can find out more about by exploring the website. 
                        I take on Freelancing jobs from time to time, so if you're here to inquire, please <a>head to my contact page!</a>
                        </p>
                    </div>
                    {/* <div className="about-banner">
                        <HomeArt />
                    </div> */}
                </div>
            </section>
            <section style={{backgroundColor: backgroundColor, color: paragraphTextColor}}>
                <div className="home-about-content-container" >
                    <div className="home-about computer-science" style={{padding: '50px 0px 0'}}>
                        <h3> Computer Science </h3>
                        {/* <p className="descriptor">I spend the vast majority of my time working with JavaScript and it's frameworks and libraries. I also know
                        Kotlin, and use Haskell to improve as a Functional Programmer. I am "self-taught", but have taken various
                        college courses online. I've been working as a Developer full time since April of 2020. The company that I currently work for
                        is a medium sized MicroBiology company, where I work on a wide range of projects across various scopes, such as 
                        creating Full-Stack websites, creating software to supplement or automate internal processes, and even creating Digital Assets for internal and external use.
                        You can check out a few recent projects below, or view my entire portfolio by going to my <a>Computer Science</a> page.
                        </p> */}
                        <p className="descriptor">I spend the majority of my time working with JavaScript and it's frameworks and libraries. I also know
                        Rust, and have been moving into TypeScript for my projects as of late. I am "self-taught", but have taken various
                        college courses online. I very much enjoy creating beautiful, responsive User Interfaces but I'm
                        equally experienced building NodeJS backends. I've been working as a Developer full time since April of 2020. Learn more on my <Link to="/computer-science"> Computer Science Page</Link>.
                        </p>
                    </div>
                </div>
            </section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{backgroundColor: backgroundColor}}>
                <path fill={backgroundColorTwo} fillOpacity="1" d="M0,192L34.3,208C68.6,224,137,256,206,234.7C274.3,213,343,139,411,122.7C480,107,549,149,617,176C685.7,203,754,213,823,202.7C891.4,192,960,160,1029,160C1097.1,160,1166,192,1234,192C1302.9,192,1371,160,1406,144L1440,128L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
            </svg>
            <section  style={{backgroundColor: backgroundColorTwo, marginTop: -5}}>
                <div className="home-about-content-container">
                    <div className="home-about personal" style={{backgroundColor: backgroundColorTwo, color: paragraphTextColor}}>
                        <h3>Personal Life</h3>
                        <p className="descriptor">
                            Finding new ways to push myself to my limit is an important part of who I am,
                            and it ensures that I'm constantly growing and discovering important things about
                            myself. I call this process Voluntary Discomfort, and you can read more about that
                            and other things about me <Link to="/blog">on my blog.</Link>
                        </p>
                        <p  className="descriptor">
                            I'm not the best at talking about myself, so much elaboration beyond that will only come
                            from getting to know me. 
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout