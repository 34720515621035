import React from "react";

const BlobOne = ({color, className}) => {
    return(
    <svg className={className} id="Layer_1" viewBox="0 0 262.74 202.94" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
        <defs/>
        <path fill={color} d="M255.2,215.82C214.25,264.1,79.77,261.14,31.49,220.2s52.45-72.13,87.15-125.08c33.34-50.89,72.47-62.91,120.75-22S296.14,167.54,255.2,215.82Z" transform="translate(-18.63 -48.53)"/>
    </svg>
    )
}

export default BlobOne;