import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import { Link } from 'gatsby';
import { AiOutlineMenu } from 'react-icons/ai';

function getWindowWidth() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser){
        const { innerWidth: width } = window;
        return width;
    }
}

const Navbar = () => {
    const [hasMounted, setHasMounted] = useState(false)
    const [viewWidth, setViewWidth] = useState(getWindowWidth() || 701);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    });

    useEffect(() => {
        setViewWidth(getWindowWidth());
    }, []);

    useEffect(() => {
        function handleResize() {
          setViewWidth(getWindowWidth());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    if(hasMounted == false){
        return null
    }

    if (viewWidth > 700){
        return(
            <nav className="navbar">
                <ul className="nav-list">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/computer-science">Comp Sci</Link></li>
                    <li><Link to="/learn">Learn</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </nav>
        )
    } else {
        return (
            <nav className="navbar-mobile">
                <AiOutlineMenu 
                    className="menu-icon" 
                    color="white" 
                    size={40} 
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
                <ul 
                    className={isMenuOpen ? "nav-list" : "nav-list-hidden"}
                >
                    <li><Link href="/">Home</Link></li>
                    <li><Link href="/computer-science">Comp Sci</Link></li>
                    <li><Link href="/learn">Learn</Link></li>
                    <li><Link href="/blog">Blog</Link></li>
                    <li><Link href="/contact">Contact</Link></li>
                    {/* { isDarkMode ? 
                        <BsSun color="white" onClick={toggleTheme} size={30} /> 
                        : 
                        <BsMoon color="white" size={30} onClick={toggleTheme} />
                    } */}
                </ul>
            </nav>
        )
    }
}

export default Navbar;