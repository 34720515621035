import React from "react";

const BlobThree = ({color, className}) => {
    return(
        <svg className={className} id="Layer_1" viewBox="0 0 197.71 227.9" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
	        <defs/>
	        <path 
                fill={color} 
                d="M169.71,263.94c-45.33-1-39.74-91.58-66.87-145.87C92.1,96.55,45.51,84.8,51.71,65.93c19.77-60.14,91.88-9.81,154.86-16.2,60.54-6.15-47,134.23,14.65,115.32C281.73,146.47,233,265.34,169.71,263.94Z" 
                transform="translate(-51.15 -36.05)"/>
        </svg>
    )
}

export default BlobThree;