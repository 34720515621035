const slides = [{
    title: 'Full Stack Engineer',
    description: 'React, NodeJS, MongoDB, MySQL, Rust and more'
},
{
    title: 'Hobbyist',
    description: 'Working Out, Martial Arts, Video Games, Chess, and more'
},
{
    title: 'Lifelong Learner',
    description: 'Just for the sake of learning'
}]


export default slides;