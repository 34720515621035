import * as React from "react"
import Hero from '../components/Hero/Hero';
import Navbar from '../components/Navbar/Navbar';
import HomeAbout from '../components/Home About/HomeAbout';
import RecentActivity from '../components/Recent Activity/RecentActivity';
import { Footer } from '../components/Footer/Footer';
import '../app.scss';

const IndexPage = () => {

  return (
      <main>
        <Hero />
        <Navbar />
        <HomeAbout />
        <RecentActivity />
        <Footer />
      </main>
  )
}

export default IndexPage
